import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "components/Layout"
import PersonalTrainingBanner from "components/Banners/PersonalTrainingBanner"
import PersonalTrainingGetStarted from "../components/PersonalTrainingGetStarted"
import PersonalTrainingCoaches from "../components/PersonalTrainingCoaches"
import HomeOmahaGyms from "components/HomeOmahaGyms"
import PersonalTrainingCommunity from "components/PersonalTrainingCommunity"
import Signup from "components/Signup"
import Seo from "components/Seo"

const getImages = graphql`
  {
    bannerPersonalTraining: file(
      relativePath: { eq: "fitfarm-omaha-personal-training-export.webp" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    getStarted: file(
      relativePath: { eq: "fitfarm-omaha-personal-training-export-1.webp" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    coaches: file(
      relativePath: { eq: "fitfarm-omaha-personal-training-export-2.webp" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    omahaGyms: file(
      relativePath: { eq: "fitfarm-omaha-crossfit-classes-for-export-4.webp" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    community: file(
      relativePath: { eq: "fitfarm-omaha-gyms-community-bg.webp" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    signup: file(
      relativePath: { eq: "fitfarm-midtown-gym-omaha-interior.webp" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const PersonalTraining = () => {
  const imgData = useStaticQuery(getImages)

  return (
    <Layout>
      <Seo
        title="Ready to Grow? Omaha Personal Training"
        description="We provide training programs in Omaha that promote a strong and healthy lifestyle. It does not matter who you are or where you are in your fitness journey."
        keywords={[
          "personal training omaha",
          "personal training",
          "omaha personal training",
        ]}
        pathname={"/personal-training/"}
      />
      <article>
        <PersonalTrainingBanner leftImage={imgData.bannerPersonalTraining} />
        <PersonalTrainingGetStarted sectionImage={imgData.getStarted} />
        <PersonalTrainingCoaches sectionImage={imgData.coaches} />
        <HomeOmahaGyms sectionImage={imgData.omahaGyms} />
        <PersonalTrainingCommunity sectionImage={imgData.community} />
        <Signup sectionImage={imgData.signup} />
      </article>
    </Layout>
  )
}

export default PersonalTraining
